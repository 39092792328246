<template>
  <div class="app">
    <div class="head">
      <header>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <headBox></headBox>
      </header>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <footBox></footBox>
  </div>
</template>
<script>
import headBox from '@/components/headBox';
import footBox from '@/components/footBox';
import '@/style/normalize.css';
export default {
  name: 'App',
  components: {
    headBox,
    footBox
  }
}
</script>
<style scoped>
.app {
  width: 100%;
}
.content {
  width: 100%;
  /* min-height: 900px; */
  margin-top: 170px;
}
.head {
  width: 100%;
  height: 170px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 480px) {
  .content {
  margin-top: 100px;
}
}
</style>
