<template>
    <div class="word_box">
        <h1>友奇环境:技术引领 助力绿色生态航运</h1>
        <p class="word_data">
            <span>Time: 2024/10/12 </span><span>Author: Kevin.GE - UPRO</span>
        </p>
        <img src="@/assets/newsImage/008(1).png" alt="">
        <img src="@/assets/newsImage/008(2).png" alt="">
    </div>
</template>
<style scoped>
@import url('@/style/wordType.css')
</style>