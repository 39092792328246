<template>
    <div style="width: 100%;margin: 30px auto 0;">
        <div class="news_box_list" style="display: flex;" v-for="(item,index) in newsList" :key="index" >
            <router-link :to="item.url">
                <div>
                    <!-- <el-image :src="require('@/assets/newsImage/' + item.imageUrl)" fit="cover" lazy /> -->
                    <img :src="require('@/assets/newsImage/' + item.imageUrl)" alt="">
                </div>
                <div class="newsTips" style="width: 70%;border: 1px solid #999; border-left: 0px;border-radius: 0 50px 0 0;" >
                    <div><span>Time：</span> <span>{{ item.newsDatas }}</span></div>
                    <div><span>Author:</span> <span>{{ item.newsAuthor }}</span></div>
                    <div><span>Title：</span> <span>{{ item.newsTitle }}</span></div>
                    <!-- <p><span>New-Info：</span>{{ item.newsTip }}</p> -->
                </div>
            </router-link>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                newsList: [
                    {
                        newsDatas: '2024/10/12',
                        newsAuthor:'友奇环境:技术引领 助力绿色生态航运',
                        newsTitle: '2018年，国际海事组织(IM0)通过《IM0船舶温室气体减排初步战略》，首次为国际航运业应对全球变暖制定碳排放目标，制定了航运业在2008年基准上，至2030年碳排放强度降低 40%，到2050年，将航运业的二氧化碳排放量较 2008 年减少 50%...',
                        // newsTip: 'Youqi Environmental Engineering has achieved multiple Chinese firsts!"',
                        imageUrl:'008.1.png',
                        url: '/info/new008'
                    },
                    {
                        newsDatas: 'May 16, 2024',
                        newsAuthor:'Upro administration office',
                        newsTitle: 'Notice of Childrens Day Charity Event',
                        // newsTip: 'Youqi Environmental Engineering has achieved multiple Chinese firsts!"',
                        imageUrl:'005.1.png',
                        url: '/info/new005'
                    },
                    {
                        newsDatas: 'May 8, 2024',
                        newsAuthor:'Kevin.GE - UPRO',
                        newsTitle: 'Awarded the 2024 Shanghai First Batch "Specialized, Refined, Distinctive, and Innovative" Enterprise Title',
                        // newsTip: "According to recent news from CCTV, the bunkering system retrofit project of “HAI YANG SHI YOU 301” ship undertaken by Guangzhou Wenchong Dockyard Co., Ltd. has been completed and officially put into use on November 15, 2022, Beijing time.",
                        imageUrl:'006.1.jpg',
                        url: '/info/new006'
                    },
                    {
                        newsDatas: 'December 14, 2023',
                        newsAuthor:'Kevin.GE - UPRO',
                        newsTitle: 'SHANGHAI UPRO MARINE SYSTEM CO., LTD.,Ltd Receives AIP Certification from RINA for LPG-Ammonia Carrier Liquid Cargo System Design Principles',
                        // newsTip:'~~',
                        imageUrl:'C001.jpg',
                        url: '/info/new003'
                    },
                    {
                        newsDatas: 'December 8, 2023',
                        newsAuthor:'Kevin.GE - UPRO',
                        newsTitle: 'Successfully Concludes Participation in Marintec China 2023',
                        // newsTip: "According to recent news from CCTV, the bunkering system retrofit project of “HAI YANG SHI YOU 301” ship undertaken by Guangzhou Wenchong Dockyard Co., Ltd. has been completed and officially put into use on November 15, 2022, Beijing time.",
                        imageUrl:'007 (7).jpg',
                        url: '/info/new007'
                    },
                    {
                        newsDatas: 'October 20, 2023',
                        newsAuthor:'Kevin.GE - UPRO',
                        newsTitle: 'Sea Trials Successful for 85K-VLGC Gas Supply System Retrofit!',
                        // newsTip: 'Youqi Environmental Engineering has achieved multiple Chinese firsts!"',
                        imageUrl:'640.png',
                        url: '/info/new001'
                    },
                    {
                        newsDatas: 'January 03, 2023',
                        newsAuthor:'Jasmina Ovcina Mandra',
                        newsTitle: 'Methanol as Marine Fuel: A Year in Review with 82 Ships Expected by 2028',
                        // newsTip: '2022 marked a triumphant year for methanol as a maritime alternative fuel, as shipowners sought solutions to decarbonize their fleets. According to DNV, by December 31, 2022, there were 82 methanol-powered ships in operation and on order.',
                        imageUrl:'D001.png',
                        url: '/info/new004'
                    },
                    {
                        newsDatas: 'November 18, 2022',
                        newsAuthor:'Kevin.GE - UPRO',
                        newsTitle: 'THE WORLD’S LARGEST LNG BUNKER - “HAI YANG SHI YOU 301” RETROFIT COMPLETED',
                        // newsTip: "According to recent news from CCTV, the bunkering system retrofit project of “HAI YANG SHI YOU 301” ship undertaken by Guangzhou Wenchong Dockyard Co., Ltd. has been completed and officially put into use on November 15, 2022, Beijing time.",
                        imageUrl:'A001.jpg',
                        url: '/info/new002'
                    },

                ],
            }
        }
    }
</script>
<style scoped>
.news_box_list {
    padding-bottom: 15px;
}
.news_box_list a {
    display: flex;
    width: 100%;
}
.news_box_list a>div{
    width: 30%;
    height: 150px;
    overflow: hidden;
}
.news_box_list img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.newsTips div{
    color: #000;
    padding: 12px;
    display: flex;
}
.newsTips div span:first-child {
    display: block;
    /* width: 80px; */
    color: #999;
    /* padding: 0 10px; */
}
.newsTips div span:nth-child(2) {
    display: block;
}
@media (max-width: 480px) {
    .newsTips div span {
        font-size: 10px;
    }
}
</style>
